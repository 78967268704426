// @ts-ignore
import Player from '@vimeo/player';

// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';

// importing alle redirect js as a part of BCC-188
import './alle-redirect';

//importing all ts files under components
import './components/**/*.ts';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  const CONSTANTS = {
    FAPSearchParam: 'location',
  };

  // handles emit events of the button components
  const handleButtonEmitEvents = () => {
    // handling mobile menu trigger and mobile brand nav trigger events
    window.Bus?.on('emu-button:click', ({ id, toggleOn }) => {
      if (id === 'brand-menu-trigger') {
        // brand menu trigger
        if (toggleOn) {
          // adding overflow hidden class and brand menu open identifier class to the body
          document.body.classList.add('mobile-menu-open', 'brand-menu-open');
        } else {
          document.body.classList.remove('mobile-menu-open', 'brand-menu-open');
        }
      } else if (id === 'main-nav-trigger') {
        // main nav/hamburger button click
        if (toggleOn) {
          // adding overflow hidden class to the body
          document.body.classList.add('mobile-menu-open');
        } else {
          // removing overflow hidden class from the body
          document.body.classList.remove('mobile-menu-open');
        }
      }
    });
  };
  // brand nav close button should close the brand nav.
  // aaaem common does not provide toggle with two different elements and so is this change, to click the brand nav open trigger with JS when brand nav close is clicked
  const handleBrandNavClose = () => {
    const brandNavCloseTrigger = document.querySelector(
      '#brand-menu-close'
    )! as HTMLButtonElement;
    const brandNavTrigger = document.querySelector(
      '#brand-menu-trigger'
    )! as HTMLButtonElement;

    // clicking the nav trigger when nav close is clicked. Nav trigger will then toggle the nav to close
    // also removing overflow hidden class to the body
    brandNavCloseTrigger?.addEventListener('click', () => {
      brandNavTrigger?.click();
    });
  };

  // mobile menu close button should close the mobile menu.
  // aaaem common does not provide toggle with two different elements and so is this change, to click the main menu open trigger with JS when main menu close is clicked
  const handleMobileNavClose = () => {
    const mainNavTrigger = document.querySelector(
      '#main-nav-trigger'
    )! as HTMLButtonElement;
    const mainNavCloseTrigger = document.querySelector(
      '#header__main-menu-close'
    )! as HTMLButtonElement;

    // clicking the mobile menu trigger when mobile menu close is clicked. mobile menu trigger will then toggle the menu to close
    // also removing overflow hidden class to the body
    mainNavCloseTrigger?.addEventListener('click', () => {
      mainNavTrigger?.click();
    });
  };

  // on mobile there is a multi level menu, while on desktop it turns to be hover dropdown
  const handleMultiLevelMenu = () => {
    const mobileMenuBreakpoint = 1100;
    const parentMenuItems = document.querySelectorAll(
      '.emu-navigation__item-parent'
    );
    if (parentMenuItems?.length) {
      parentMenuItems.forEach(el => {
        const trigger = el?.querySelector('a') as HTMLAnchorElement;
        trigger.addEventListener('click', e => {
          if (window.innerWidth < mobileMenuBreakpoint) {
            e.preventDefault();
            document.body.classList.add('mobile-submenu-open');
          }
        });

        const backTrigger = el?.querySelector('a[href="#back"]');
        backTrigger?.addEventListener('click', e => {
          e.preventDefault();
          trigger.click();
          document.body.classList.add('mobile-submenu-open');
        });
      });
    }
  };

  // simple debounced function
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const args = arguments;
      //@ts-ignore
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  // on desktop, when user scrolls by 50px, the menu's layout must change to hide the logo and eyebrow menu
  const handleMenuScrollLayout = () => {
    const botoxDayBannerEl = document.querySelector(
      '.botox-day-banner-container'
    );
    let scrollPos = 50;

    // when the botox day banner is present, make the header fixed only after the page is scrolled past the height of the banner
    if (botoxDayBannerEl) {
      document.body.classList.add('has-botox-day-banner');
      const calcScrollPos = () => {
        scrollPos = botoxDayBannerEl.clientHeight + 50;
      };
      window.addEventListener('resize', calcScrollPos);
      calcScrollPos();
    }

    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > scrollPos) {
        document.body.classList.add('has-header-affixed');
      } else {
        document.body.classList.remove('has-header-affixed');
      }
    });
  };

  // accordions' content when they have image is getting hidden as the height calculations are being done before the image gets loaded
  // re-calculating the height of panels when accordion is toggled, to make sure that the panels are shown without chopping off content and without any white spaces
  const handleAccordions = () => {
    window.Bus.on('emu-accordion:toggle', ({ id, itemClicked }) => {
      const badgerInstance = window._badgerInstances[id];
      const accEl = document.querySelector(`#${id}`);
      if (accEl && badgerInstance && itemClicked?.id) {
        const itemClickedButton = accEl
          .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
          ?.getAttribute('aria-controls');
        const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

        if (accordionContent) {
          badgerInstance.calculatePanelHeight(accordionContent);
        }
      }
    });
  };

  // in real stories page, only 4 gallery items must be shown on page load.
  // Every time see more button is clicked, 4 more gallery items will be shown.
  // Once all items are shown, the button text turns to see less. Clicking see less button hides all gallery items except first 4
  const toggleGalleryItems = (
    gallery: HTMLElement,
    galleryItems: NodeList,
    toggleFactor: number,
    firstLoad?: boolean
  ) => {
    const visibleGalleryItems = gallery.querySelectorAll(
      `.emu-gallery__preview-wrapper > .emu-gallery__preview-item:not(.gi-invisible)`
    ) as NodeList;

    let visibleLen = visibleGalleryItems.length;
    const galleryLen = galleryItems.length;

    if (visibleGalleryItems.length === galleryLen) {
      const galleryStage = document.querySelector(
        '.emu-gallery__stage-wrapper'
      ) as HTMLElement;

      if (!firstLoad) {
        scrollToElem(galleryStage);
      }

      galleryItems.forEach((el, i) => {
        if (i > toggleFactor - 1) {
          (<HTMLElement>el).classList.add('gi-invisible');
          visibleLen -= 1;
        }
      });
    } else {
      for (
        let i = visibleGalleryItems.length;
        i < visibleGalleryItems.length + toggleFactor;
        i += 1
      ) {
        const gItem = <HTMLElement>galleryItems[i];
        if (gItem) {
          gItem.classList.remove('gi-invisible');
          visibleLen += 1;
        }
      }
    }
    return visibleLen >= galleryLen;
  };

  // scrolls the page to show the gallery stage section at the top of the viewport
  const scrollToElem = el => {
    if (el) {
      const header = document.querySelector(
        '.header__container'
      ) as HTMLElement;
      const scrollPos =
        el.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        header.clientHeight -
        40; // 40 is to show white space above the element to be shown in viewport

      window.scrollTo({
        top: scrollPos,
        behavior: 'smooth',
      });
    }
  };

  /**
   * Stops any vimeo iframe present inside a container
   * @param {HTMLElement} - el - wrapper of the iframe that contains vimeo video
   */
  const stopVimeoVideo = el => {
    if (el) {
      const curIframe = (<HTMLElement>el).querySelector('iframe');
      try {
        const player = new Player(curIframe);
        player?.unload?.();
      } catch (e) {
        console.warn(e);
      }
    }
  };

  /**
   * Plays any vimeo iframe present inside a container
   * @param {HTMLElement} - el - wrapper of the iframe that contains vimeo video
   */
  const playVimeoVideo = el => {
    let curIframe = (<HTMLElement>el)?.querySelector('iframe');
    if (curIframe) {
      try {
        const player = new Player(curIframe);
        player?.play?.();
      } catch (e) {
        console.warn(e);
      }
    }
  };

  // initialize gallery related functionalities
  const initGallery = (gallery: HTMLElement) => {
    const toggleFactor = 4;
    const galleryItems = gallery.querySelectorAll(
      '.emu-gallery__preview-wrapper > .emu-gallery__preview-item'
    ) as NodeList;
    const galleryStageItems = gallery.querySelectorAll(
      '.emu-gallery__stage-item'
    ) as NodeList;
    const parentContainer = gallery.closest(
      '.more-stories__container'
    ) as HTMLElement;
    const seeMoreBtn = parentContainer.querySelector(
      '.more-stories__button'
    ) as HTMLButtonElement;

    // when gallery item is changed
    //   - scroll the page to show gallery in the top of the viewport
    //   - autoplay the selected video
    // Note: Bus events from aaaem common are not sufficient here as there will be a slight delay between user click and the callback execution
    // so, by DOM policy autoplay does not work. To make it work, making sure that the click play event happens on click listener
    galleryItems.forEach((e, i) => {
      e.addEventListener('click', () => {
        const galleryStage = document.querySelector(
          '.emu-gallery__stage-wrapper'
        ) as HTMLElement;

        scrollToElem(galleryStage);
        playVimeoVideo(galleryStageItems[i]);

        // resetting iframes' src of non active gallery items
        galleryStageItems.forEach((el, index) => {
          if (index !== i) {
            stopVimeoVideo(el);
          }
        });
      });
    });

    if (seeMoreBtn) {
      // hiding show more button if no. of gallery items is less than toggle factor
      if (galleryItems.length <= toggleFactor) {
        seeMoreBtn.style.display = 'none';
      }

      // show first four items on page load
      toggleGalleryItems(gallery, galleryItems, toggleFactor, true);

      // event on button to show more/ show less gallery items
      seeMoreBtn.addEventListener('click', () => {
        const shownAll = toggleGalleryItems(
          gallery,
          galleryItems,
          toggleFactor
        );

        if (shownAll) {
          seeMoreBtn.classList.add('more-stories__button--see-less');
        } else {
          seeMoreBtn.classList.remove('more-stories__button--see-less');
        }
      });
    }
  };

  // Handle the gallery list on the real-stories page
  // This should take care of see more button and the on click functionality of each gallery item
  const handleGalleryList = () => {
    if (document.querySelector('#real-stories-page')) {
      const galleries = document.querySelectorAll('.emu-gallery') as NodeList;
      if (galleries.length) {
        galleries.forEach(el => {
          initGallery(<HTMLElement>el);
        });
      }
    }
  };

  // back to top element is present in two places, ISI and in mobile footer
  // since the styles of the back to top element in mobile footer is very similar to the other footer links, instead of add a new backtotop component into footer,
  // added a JS event that clicks the backToTop button in ISI when backToTop in footer is clicked.
  const handleFooterBackTop = () => {
    const bTopTrigger = document.querySelector('.backToTop > a') as HTMLElement;
    const footerEl = document.querySelector(
      '.footer__container--secondary'
    ) as HTMLElement;

    const bTopLink = footerEl?.querySelector(
      'ul li:last-child a'
    ) as HTMLAnchorElement;

    bTopLink?.addEventListener('click', e => {
      e.preventDefault();
      bTopTrigger?.click();
      return false;
    });
  };

  // functionality for comparison slider carousel in before-and-after/Real Results Page
  const handleComparisonSliderCarousel = () => {
    let activeSlide;
    let activeSection;
    let firstLoad = true;
    const mainCarouselInst = window._tnsInstances?.['ba-carousel'];
    const mainCarouselInitialInfo = mainCarouselInst?.getInfo?.();
    const miniCarouselInst = window._tnsInstances?.['ba-mini-carousel'];
    const carousel = document.querySelector(
      '#real-results-page .ba-carousel__wrapper'
    ) as HTMLElement;
    const miniCarouselImgs = carousel?.querySelectorAll(
      '.ba-mini-carousel .emu-image'
    );
    const carouselMainAssetsWrapper = carousel?.querySelector(
      '.ba-carousel__assets-wrapper'
    ) as HTMLElement;

    const zoomButtons = carousel?.querySelectorAll(
      '.ba-carousel__control--zoom'
    );
    const lineButtons = carousel?.querySelectorAll(
      '.ba-carousel__control--lines'
    );
    const controlButtons = carousel?.querySelectorAll('.ba-carousel__control');
    const zoomInCta = carousel?.querySelector(
      '#ba-carousel__control-zoom-in'
    ) as HTMLButtonElement;
    const zoomOutCta = carousel?.querySelector(
      '#ba-carousel__control-zoom-out'
    ) as HTMLButtonElement;
    const frownLinesCta = carousel?.querySelector(
      '#ba-carousel__control-frown-lines'
    ) as HTMLButtonElement;
    const crowsFeetCta = carousel?.querySelector(
      '#ba-carousel__control-crows-feet'
    ) as HTMLButtonElement;
    const foreheadLinesCta = carousel?.querySelector(
      '#ba-carousel__control-forehead-lines'
    ) as HTMLButtonElement;
    const mediaCta = carousel?.querySelector(
      '#ba-carousel__control-media'
    ) as HTMLButtonElement;

    // Switches between zoom in and zoom out version of the selected section(frown/crow/forehead)
    // Toggles active state of zoom-in and zoom-out buttons
    const showZoom = (type: 'zoom-in' | 'zoom-out') => {
      if (activeSection && activeSlide && type) {
        const currentSection = activeSection.querySelector(
          '.ba-carousel__comparison-slider--active'
        );
        if (currentSection) {
          currentSection.classList.remove(
            'ba-carousel__comparison-slider--active'
          );
        }

        const zoomSection = activeSection.querySelector(
          `.ba-carousel__comparison-slider--${type}`
        );

        if (zoomSection) {
          zoomSection?.classList.add('ba-carousel__comparison-slider--active');
          zoomButtons?.forEach(el => {
            if (el.classList.contains(`ba-carousel__control--${type}`)) {
              toggleCtaState(false, <HTMLButtonElement>el);
            } else if (
              !el.classList.contains('ba-carousel__control--cannot-enable')
            ) {
              toggleCtaState(true, <HTMLButtonElement>el);
            }
          });
        } else {
          currentSection?.classList.add(
            'ba-carousel__comparison-slider--active'
          );
        }

        adjustCarouselHeight();
      }
    };

    //
    /**
     * switches between different available selections based on the button clicked by the user
     * @param {string} - type of the section to be shown
     */
    const showSection = (
      type: 'frown-lines' | 'crows-feet' | 'forehead-lines' | 'media'
    ) => {
      const slideInfo = mainCarouselInst?.getInfo();
      const { index, slideItems } = slideInfo;

      // stopping any playing vimeo from previous slide
      stopVimeoVideo(activeSlide);

      activeSlide = slideItems?.[index];

      if (activeSlide) {
        // adding active button and removing previous active button
        controlButtons?.forEach(e => {
          if (e.classList.contains(`ba-carousel__control--${type}`)) {
            e.classList.add('ba-carousel__control--active');
          } else {
            e.classList.remove('ba-carousel__control--active');
          }
        });

        // adding active section and removing previous active section
        const slideSections = activeSlide?.querySelectorAll(
          '.ba-carousel__slide-section'
        );
        slideSections?.forEach(e => {
          if (e.classList.contains(`ba-carousel__slide-section--${type}`)) {
            e.classList.add('ba-carousel__slide-section--active');
            activeSection = e;
          } else {
            e.classList.remove('ba-carousel__slide-section--active');
          }
        });

        setZoomInitialButtonStates();

        adjustCarouselHeight();

        // for media there is a close icon, that needs an event listener to close the media section
        // when media is active hide before after text
        if (type === 'media') {
          carousel?.classList?.add('ba-carousel--media-visible');
          const closeCta = activeSection.querySelector(
            '.close-btn'
          ) as HTMLButtonElement;
          if (closeCta) {
            closeCta.removeEventListener('click', setCarouselSlide);
            closeCta.addEventListener('click', setCarouselSlide, {
              once: true,
            });
          }
        } else {
          carousel?.classList?.remove('ba-carousel--media-visible');
        }

        // scroll to the top of the page
        if (firstLoad) {
          firstLoad = false;
        } else {
          scrollToElem(carousel);
        }
      }
    };

    // updates active type and active slide that are used to show/hide sections and sliders
    const updateSlideAndType = () => {
      const slideInfo = mainCarouselInst?.getInfo();
      const { index, slideItems } = slideInfo;
      activeSlide = slideItems?.[index];
      activeSection = activeSlide.querySelector(
        '.ba-carousel__slide-section--active'
      );

      // adding active class to the thumbnail carousel to show border
      miniCarouselImgs?.forEach((el, i) => {
        if (index !== i) {
          el.classList.remove('active');
        } else {
          el.classList.add('active');
        }
      });
    };

    /**
     * toggles buttons' state to add/remove disabled/enabled
     * @param {Boolean} on - show the button as turned on/off
     * @param {Element} cta - button element
     */
    const toggleCtaState = (on: Boolean, cta: HTMLButtonElement) => {
      if (cta) {
        if (on) {
          cta.removeAttribute('disabled');
        } else {
          cta.setAttribute('disabled', 'disabled');
        }
      }
    };

    // set the initial states(disabled/enabled) of the buttons based on availability if sections in the active slide
    const setInitialButtonStates = () => {
      if (activeSlide) {
        const hasFrownLines = activeSlide.querySelector(
          '.ba-carousel__slide-section--frown-lines'
        );
        const hasCrowsFeet = activeSlide.querySelector(
          '.ba-carousel__slide-section--crows-feet'
        );
        const hasForeheadLines = activeSlide.querySelector(
          '.ba-carousel__slide-section--forehead-lines'
        );
        const media = activeSlide.querySelector(
          '.ba-carousel__slide-section--media .aaaem-embed__iframe'
        );
        const hasMedia =
          media?.getAttribute('src') ||
          media?.getAttribute('data-iframe-src') ||
          false;

        toggleCtaState(hasFrownLines, frownLinesCta);
        toggleCtaState(hasCrowsFeet, crowsFeetCta);
        toggleCtaState(hasForeheadLines, foreheadLinesCta);
        toggleCtaState(hasMedia, mediaCta);
      }
    };

    // Decides initial states of the zoom buttons on whether zoom in/ zoom out must be enabled/disabled
    // Makes the initial zoom section of active slide to visible
    const setZoomInitialButtonStates = () => {
      if (activeSection) {
        const hasZoomIn = activeSection?.querySelector(
          '.ba-carousel__comparison-slider--zoom-in'
        );
        const hasZoomOut = activeSection?.querySelector(
          '.ba-carousel__comparison-slider--zoom-out'
        );

        // if either of zoom in or zoom out is not available, disable both buttons
        if (!hasZoomIn || !hasZoomOut) {
          toggleCtaState(false, zoomInCta);
          toggleCtaState(false, zoomOutCta);
          zoomInCta.classList.add('ba-carousel__control--cannot-enable');
          zoomOutCta.classList.add('ba-carousel__control--cannot-enable');
        }

        // if both are present, show zoom out version first, disable zoom out button
        // if zoom-in is not present, show zoom-out
        // if zoom-out is not present, show zoom-in
        if (hasZoomOut && hasZoomIn) {
          toggleCtaState(true, zoomInCta);
          toggleCtaState(false, zoomOutCta);
          showZoom('zoom-out');
          zoomInCta.classList.remove('ba-carousel__control--cannot-enable');
          zoomOutCta.classList.remove('ba-carousel__control--cannot-enable');
        } else if (hasZoomOut && !hasZoomIn) {
          showZoom('zoom-out');
        } else if (hasZoomIn && !hasZoomOut) {
          showZoom('zoom-in');
        }
      }
    };

    // appends events on the control buttons
    const appendControlEvents = () => {
      window.Bus.on('emu-button:click', ({ id }) => {
        switch (id) {
          case 'ba-carousel__control-zoom-in':
            showZoom('zoom-in');
            break;
          case 'ba-carousel__control-zoom-out':
            showZoom('zoom-out');
            break;
          case 'ba-carousel__control-frown-lines':
            showSection('frown-lines');
            break;
          case 'ba-carousel__control-crows-feet':
            showSection('crows-feet');
            break;
          case 'ba-carousel__control-forehead-lines':
            showSection('forehead-lines');
            break;
          case 'ba-carousel__control-media':
            showSection('media');
            break;
        }
      });
    };

    // The images are set to lazyload inside the carousel to achieve better performance.
    // However, when the user switches between slides, the height of the carousel becomes zero. As a result, when the image loads again, the height adjusts, creating a flickering effect.
    // To avoid the flicker but maintain performance, adding a min-height to the wrapper, so that the flickering does not happen
    // The min-height will be added after all the images present in the activeSlide are loade
    const adjustCarouselHeight = () => {
      if (carouselMainAssetsWrapper) {
        const carouselInst = mainCarouselInst?.getInfo();
        const activeSlide = carouselInst.slideItems[carouselInst.index];
        if (activeSlide) {
          const slideImgs = activeSlide.querySelectorAll(
            'img'
          ) as NodeListOf<HTMLImageElement>;
          if (slideImgs?.length) {
            Promise.all(
              Array.from(slideImgs)
                .filter(img => !img.complete)
                .map(
                  img =>
                    new Promise(resolve => {
                      img.onload = img.onerror = resolve;
                    })
                )
            ).then(() => {
              carouselMainAssetsWrapper.style.minHeight = '';
              mainCarouselInst?.updateSliderHeight();
              queueMicrotask(() => {
                carouselMainAssetsWrapper.style.minHeight =
                  carouselMainAssetsWrapper.clientHeight + 'px';
                carouselMainAssetsWrapper?.classList.add(
                  'ba-carousel__assets-wrapper--loaded'
                );
              });
            });
          } else {
            mainCarouselInst?.updateSliderHeight();
            carouselMainAssetsWrapper?.classList.add(
              'ba-carousel__assets-wrapper--loaded'
            );
          }
        }
      }
    };

    // sets the carousel slide, makes the first available section active and sets the control button states(enabled/disabled)
    const setCarouselSlide = () => {
      carouselMainAssetsWrapper?.classList.remove(
        'ba-carousel__assets-wrapper--loaded'
      );
      updateSlideAndType();
      setInitialButtonStates();

      let buttonSelected = false;
      lineButtons.forEach(e => {
        if (!e.getAttribute('disabled') && !buttonSelected) {
          buttonSelected = true;
          (<HTMLButtonElement>e).click();
        }
      });
    };

    // this is where the initialization starts for the carousel
    if (carousel && mainCarouselInst) {
      appendControlEvents();
      // whenever slide is changed, make the first available sections active and sets the control button states(enabled/disabled)
      mainCarouselInst.events.on('transitionStart', setCarouselSlide);

      window.addEventListener(
        'resize',
        debounce(() => {
          adjustCarouselHeight();
        }, 50)
      );

      // setting the first carousel slide's sections to active
      setCarouselSlide();

      if (miniCarouselInst) {
        // whenever mini thumbnail carousel item is clicked, the corresponding main carousel item should be shown
        miniCarouselImgs?.forEach((e, i) => {
          e.addEventListener('click', () => {
            if (mainCarouselInitialInfo.slideItems[i]) {
              mainCarouselInst?.goTo?.(i);
            }
          });
        });
      }
    }
  };

  // functionality for video carousel in before-and-after/Real results page
  const handleVideoCarousel = () => {
    const videoCarouselInst = window._tnsInstances?.['ba-video-carousel'];
    if (videoCarouselInst) {
      // autoplay current slide video, stop previous slide video, make the info of current slide visible
      videoCarouselInst.events.on('indexChanged', info => {
        const { index, indexCached, slideItems } = info;
        const prevSlide = slideItems[indexCached];
        const curSlide = slideItems[index];

        if (prevSlide) {
          stopVimeoVideo(prevSlide);
          prevSlide
            ?.querySelector('.ba-video-carousel__item-info')
            ?.classList?.remove('ba-video-carousel__item-info--show');
        }

        if (curSlide) {
          playVimeoVideo(curSlide);
          curSlide
            ?.querySelector('.ba-video-carousel__item-info')
            ?.classList?.add('ba-video-carousel__item-info--show');
        }
      });

      // making info of first slide visible
      const carouselInfo = videoCarouselInst.getInfo();
      if (carouselInfo) {
        const { index, slideItems } = carouselInfo;
        const slide = slideItems?.[index];
        if (slide) {
          slide
            ?.querySelector('.ba-video-carousel__item-info')
            ?.classList?.add('ba-video-carousel__item-info--show');
        }
      }
    }
  };

  // when window is resized, the carousel does not update heights causing UI to appear broken. Updating carousel height whenever screen is re-sized
  const handleCarouselResize = () => {
    if (window._tnsInstances) {
      const carouselInstances: { updateSliderHeight }[] = Object.values(
        window._tnsInstances
      );
      if (carouselInstances.length > 0) {
        window.addEventListener(
          'resize',
          debounce(() => {
            carouselInstances.forEach(carouselInst => {
              carouselInst?.updateSliderHeight?.();
            });
          }, 50)
        );
      }
    }
  };

  // this function is used to detect os of the device
  // detection of OS is needed, as the font is rendering differently in windows than in the rest of the OS
  const detectOs = () => {
    const userOs = navigator.userAgent.toLowerCase();
    if (userOs.indexOf('windows') !== -1) {
      document.body.classList.add('windows-os');
    } else if (
      /(iPad|iPhone|iPod)/g.test(navigator.userAgent) &&
      navigator.maxTouchPoints
    ) {
      document.body.classList.add('ios-os');
    }
  };

  // read more button in real impact page
  const handleReadMore = () => {
    const readMore = document.querySelectorAll(
      '.real-impact__spotlight-card-content-button'
    ) as NodeListOf<HTMLElement>;
    if (readMore?.length) {
      readMore?.forEach(el => {
        el.addEventListener('click', () => {
          const moreTextElem = el
            .closest('.real-impact__spotlight-card-content')
            ?.querySelector(
              '.real-impact__spotlight-card-content-text'
            ) as HTMLElement;
          const shouldExpand = el.classList.contains('js-toggle-on');
          if (shouldExpand) {
            moreTextElem.style.display = 'block';
            moreTextElem?.classList?.add(
              'real-impact__spotlight-card-content-text--more'
            );
          } else {
            moreTextElem.addEventListener(
              'transitionend',
              () => {
                moreTextElem.style.display = '-webkit-box';
              },
              {
                once: true,
              }
            );
            moreTextElem?.classList?.remove(
              'real-impact__spotlight-card-content-text--more'
            );
          }
        });
      });
    }
  };

  const init = () => {
    if (window.Bus) {
      handleAccordions();
      handleButtonEmitEvents();
      // handleIfwCards();
    }

    handleBrandNavClose();
    handleMobileNavClose();
    handleMultiLevelMenu();
    handleMenuScrollLayout();
    handleFooterBackTop();
    handleGalleryList();
    handleComparisonSliderCarousel();
    handleCarouselResize();
    handleVideoCarousel();
    handleReadMore();
    detectOs();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
